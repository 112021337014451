import { DateTime } from 'luxon'

import isNil from 'lodash/isNil'

import { DatesListItem } from 'components/blocks/__v3__/HookForm/FormSelectedDatesList'

import { excludedDates, partialInputsToDateTimes } from '../../../helpers'

type FormData = {
  customLeaveDayId?: string
  employeeId?: string
  dates: DatesListItem[]
  date?: string
  startTime?: number
  endTime?: number
  partial: boolean
  period?: {
    from: string
    to: string
  }
}

export function mapFormDataToServerData({
  customLeaveDayId,
  employeeId,
  dates,
  date,
  startTime,
  endTime,
  partial,
  period,
}: FormData): Gateway.EstimateBalanceImpactInput | null {
  if (!customLeaveDayId || !employeeId) return null

  const payload = {
    customLeaveDayId,
    employeeId,
    partial,
  }

  if (partial) {
    if (!date || isNil(startTime) || isNil(endTime)) return null

    const { startDatetime, endDatetime } = partialInputsToDateTimes(
      date,
      startTime,
      endTime,
    )

    return {
      ...payload,
      startAt: startDatetime.toUTC().toISO(),
      endAt: endDatetime.toUTC().toISO(),
      excludedDates: [],
    }
  }

  if (!period) return null

  return {
    ...payload,
    startAt: DateTime.fromISO(period.from).startOf('day').toUTC().toISO(),
    endAt: DateTime.fromISO(period.to).endOf('day').toUTC().toISO(),
    excludedDates: dates.filter(excludedDates).map(dateItem => dateItem.label),
  }
}
