export enum Plan {
  // Clients

  WorkAxleComplete = 'WorkAxle Complete',
  WorkAxleManagement = 'WorkAxle Management',
  WorkAxleCommunity = 'WorkAxle Community',
  WorkAxleJobs = 'WorkAxle Jobs',
  WorkaxleClients = 'Workaxle Clients',
  WorkaxleProduct = 'WorkAxle Product',
  // ETFO
  Etfo = 'ETFO',
  // Deluxe
  Deluxe = 'Deluxe',
  // keywords
  Keywords = 'Keywords',
  // Aldo
  Aldo = 'ALDO',
  // Garda
  Garda = 'Garda',
  // Netease
  Netease = 'Netease',
  // Fraport
  Fraport = 'FPS',
  // ICC
  ICC = 'ICC',
  // TBS
  TBS = 'TBS',

  // Features
  // Note: This is a Plan for the older PoC version of the feature
  AutoAssignment = 'Auto assignment',

  // Note: This is a Plan for the newer and more versatile version of the feature, to replace 'Auto assignment' when backwards compatible
  AutoAssignmentV2 = 'Auto assignment V2',

  NLP = 'NLP',
  PoC = 'BI',
  TimeBuckets = 'Cost-Groups',
  Integrations = 'Integrations',
  WeeklyTimesheets = 'Weekly-Timesheets',
  WorkflowBuilder = 'Workflow Builder',
  ShiftEditOverrideForAssignment = 'Shift Edit Override for Assignments',
  ShiftBidding = 'Shift Bidding',
  LeaveBalanceInfo = 'Leave Balance Info',
  UnpublishSchedules = 'Unpublish Schedules',
}

export const SUBSCRIPTION_STATUS = {
  trialing: 'trialing',
  active: 'active',
  past_due: 'past_due',
  canceled: 'canceled',
  unpaid: 'unpaid',
}

export enum Feature {
  Schedule = 'schedule',
  Shifts = 'shifts',
  TimeCards = 'timeCards',
  Employees = 'employees',
  EmployeesInvite = 'employeesInvite',
  EmployeesTags = 'employeesTags',
  EmploymentTerms = 'employmentTerms',
  Notifications = 'notifications',
  NotificationsPage = 'notificationsPage',
  News = 'news',
  Chats = 'chats',
  AccessLevels = 'accessLevels',
  Clocks = 'clocks',
  Payroll = 'payroll',
  PublicHolidays = 'publicHolidays',
  Clients = 'clients',
  Locations = 'locations',
  TimeCardsAutoSettings = 'timeCardsAutoSettings',
  TimeAndAttendance = 'timeAndAttendance',
  ReportBuilder = 'reportBuilder',
  FormBuilder = 'formBuilder',
  PrayerBreaks = 'prayerBreaks',
  CustomLabelDays = 'customLabelDays',
  MassImport = 'massImport',
  DailyWorkingHours = 'dailyWorkingHours',
  TaskBuilder = 'taskBuilder',
  Leaves = 'leaves',
  BulkShiftImport = 'bulkShiftImport',
  Hathor = 'hathor',
  Dashboard = 'dashboard',
  BulkImport = 'bulkImport',
  Availability = 'availability',
  TimeOffs = 'timeOffs',
  WorkSchedule = 'workSchedule',
  Classifiers = 'classifiers',
  Groups = 'groups',
  TimeBuckets = 'timeBuckets',
  WorkWeek = 'workWeek',
  TimeType = 'timeType',
  AutoShiftName = 'autoShiftName',
  DeluxePayroll = 'deluxePayroll',
  AldoPayroll = 'aldoPayroll',
  PayFrequency = 'payFrequency',
  PayrollAccount = 'payrollAccount',
  CustomFields = 'customFields',
  ShiftTags = 'shiftTags',
  GlobalSearch = 'globalSearch',
  EarningType = 'earningType',
  ShiftBidding = 'shiftBidding',

  // Note: This is a Plan for the older PoC version of the feature
  AutoAssignment = 'autoAssignment',
  // Note: This is a Plan for the newer and more versatile version of the feature, to replace 'Auto assignment' when backwards compatible
  AutoAssignmentV2 = 'autoAssignmentV2',

  Integrations = 'integrations',
  IntegrationsSettings = 'integrationsSettings',
  StatutoryHoliday = 'statutoryHoliday',
  NLP = 'nlp',
  TimeEvaluationLogs = 'timeEvaluationLogs',
  AllowDoubleBooking = 'allowDoubleBooking',
  WeeklyTimesheets = 'weeklyTimesheets',

  // Workflow builder
  WorkflowBuilder = 'workflowBuilder',
  TimeClassification = 'timeClassification',
  TimeEvaluation = 'timeEvaluation',
  ShiftValidation = 'shiftValidation',

  // Unbpublish schedules
  UnpublishSchedules = 'unpublishSchedules',

  // Garda features
  GardaEmployeesDaily = 'gardaEmployeesDaily',
  GardaReports = 'gardaReports',

  // Demo features
  DemandForecastingPoC = 'demandForecastingPoC',
  RuleBuilderPoC = 'ruleBuilderPoC',
  WeeklyTimesheetsPoC = 'weeklyTimesheetsPoC',
  ShiftEditOverrideForAssignment = 'shiftEditOverrideForAssignment',

  ShiftEditingThreshold = 'shiftEditingThreshold',

  LeaveBalanceInfo = 'Leave Balance Info',
}

export enum ItemType {
  Link = 'link',
  Action = 'Action',
}

type Products = {
  [key in Plan]: Feature[]
}

export const PRODUCT: Products = {
  [Plan.Garda]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.GardaReports,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.AccessLevels,
    Feature.Clocks,
    Feature.PublicHolidays,
    Feature.Clients,
    Feature.Locations,
    Feature.TimeCardsAutoSettings,
    Feature.TimeAndAttendance,
    Feature.ReportBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.Leaves,
    Feature.Dashboard,
    Feature.TimeOffs,
    Feature.Classifiers,
    Feature.TimeType,
    Feature.ShiftTags,
    Feature.CustomFields,
    Feature.Groups,
    Feature.StatutoryHoliday,
    Feature.Integrations,
    Feature.GardaEmployeesDaily,
    Feature.EmploymentTerms,
  ],
  [Plan.Deluxe]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.News,
    Feature.Chats,
    Feature.AccessLevels,
    Feature.Clocks,
    Feature.PublicHolidays,
    Feature.Clients,
    Feature.Locations,
    Feature.TimeCardsAutoSettings,
    Feature.TimeAndAttendance,
    Feature.ReportBuilder,
    Feature.FormBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.TaskBuilder,
    Feature.Leaves,
    Feature.BulkShiftImport,
    Feature.Hathor,
    Feature.Dashboard,
    Feature.BulkImport,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.Classifiers,
    Feature.Groups,
    Feature.WorkWeek,
    Feature.TimeType,
    Feature.AutoShiftName,
    Feature.DeluxePayroll,
    Feature.PayFrequency,
    Feature.PayrollAccount,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.EarningType,
    Feature.StatutoryHoliday,
    Feature.EmploymentTerms,
  ],
  [Plan.Keywords]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.AccessLevels,
    Feature.PublicHolidays,
    Feature.Clients,
    Feature.Locations,
    Feature.TimeAndAttendance,
    Feature.TimeCardsAutoSettings,
    Feature.ReportBuilder,
    Feature.FormBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.TaskBuilder,
    Feature.Leaves,
    Feature.BulkShiftImport,
    Feature.Dashboard,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.Classifiers,
    Feature.Groups,
    Feature.WorkWeek,
    Feature.TimeType,
    Feature.AutoShiftName,
    Feature.PayFrequency,
    Feature.PayrollAccount,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.GlobalSearch,
    Feature.AllowDoubleBooking,
    Feature.Integrations,
    Feature.IntegrationsSettings,
    Feature.EmploymentTerms,
  ],
  [Plan.Aldo]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.AccessLevels,
    Feature.Clocks,
    Feature.PublicHolidays,
    Feature.Clients,
    Feature.Locations,
    Feature.TimeAndAttendance,
    Feature.TimeCardsAutoSettings,
    Feature.ReportBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.Leaves,
    Feature.Dashboard,
    Feature.TimeOffs,
    Feature.Classifiers,
    Feature.TimeType,
    Feature.PayFrequency,
    Feature.ShiftTags,
    Feature.CustomFields,
    Feature.WorkWeek,
    Feature.Groups,
    Feature.Integrations,
    Feature.AldoPayroll,
    Feature.StatutoryHoliday,
    Feature.TimeEvaluationLogs,
    Feature.WeeklyTimesheets,
    Feature.EmploymentTerms,
  ],
  [Plan.Etfo]: [
    Feature.Employees,
    Feature.TimeCards,
    Feature.Locations,
    Feature.ReportBuilder,
    Feature.TimeAndAttendance,
    Feature.TimeCardsAutoSettings,
    Feature.EmploymentTerms,
  ],
  [Plan.Netease]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.News,
    Feature.Chats,
    Feature.AccessLevels,
    Feature.Clocks,
    Feature.Payroll,
    Feature.PublicHolidays,
    Feature.Clients,
    Feature.Locations,
    Feature.TimeCardsAutoSettings,
    Feature.TimeAndAttendance,
    Feature.ReportBuilder,
    Feature.FormBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.TaskBuilder,
    Feature.Leaves,
    Feature.BulkShiftImport,
    Feature.Hathor,
    Feature.Dashboard,
    Feature.BulkImport,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.Classifiers,
    Feature.Groups,
    Feature.WorkWeek,
    Feature.TimeType,
    Feature.AutoShiftName,
    Feature.PayFrequency,
    Feature.PayrollAccount,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.GlobalSearch,
    Feature.Integrations,
    Feature.IntegrationsSettings,
    Feature.EmploymentTerms,
  ],
  [Plan.Fraport]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.News,
    Feature.Chats,
    Feature.AccessLevels,
    Feature.PublicHolidays,
    Feature.Locations,
    Feature.TimeAndAttendance,
    Feature.TimeCardsAutoSettings,
    Feature.ReportBuilder,
    Feature.CustomLabelDays,
    Feature.DailyWorkingHours,
    Feature.Leaves,
    Feature.Dashboard,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.Classifiers,
    Feature.Groups,
    Feature.AutoShiftName,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.Integrations,
    Feature.IntegrationsSettings,
    Feature.EmploymentTerms,
    // Note: leaving here for now until we have a super admin panel
    Feature.AutoAssignmentV2,
  ],
  [Plan.WorkAxleComplete]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.News,
    Feature.Chats,
    Feature.AccessLevels,
    Feature.Clocks,
    Feature.Payroll,
    Feature.PublicHolidays,
    Feature.Clients,
    Feature.Locations,
    Feature.TimeCardsAutoSettings,
    Feature.TimeAndAttendance,
    Feature.ReportBuilder,
    Feature.FormBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.TaskBuilder,
    Feature.Leaves,
    Feature.BulkShiftImport,
    Feature.Hathor,
    Feature.Dashboard,
    Feature.BulkImport,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.WorkSchedule,
    Feature.Classifiers,
    Feature.Groups,
    Feature.WorkWeek,
    Feature.TimeType,
    Feature.AutoShiftName,
    Feature.PayFrequency,
    Feature.PayrollAccount,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.GlobalSearch,
    Feature.EarningType,
    Feature.EmploymentTerms,
  ],
  [Plan.WorkaxleProduct]: [
    // All features are included in this plan
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.News,
    Feature.Chats,
    Feature.AccessLevels,
    Feature.Clocks,
    Feature.Payroll,
    Feature.PublicHolidays,
    Feature.Clients,
    Feature.Locations,
    Feature.TimeAndAttendance,
    // Note: leaving here for now until we have a super admin panel
    Feature.TimeBuckets,
    Feature.TimeCardsAutoSettings,
    Feature.ReportBuilder,
    Feature.FormBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.TaskBuilder,
    Feature.Leaves,
    Feature.BulkShiftImport,
    Feature.Hathor,
    Feature.Dashboard,
    Feature.BulkImport,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.WorkSchedule,
    Feature.Classifiers,
    Feature.Groups,
    Feature.WorkWeek,
    Feature.TimeType,
    Feature.AutoShiftName,
    Feature.PayFrequency,
    Feature.PayrollAccount,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.GlobalSearch,
    Feature.EarningType,
    Feature.Integrations,
    Feature.IntegrationsSettings,
    Feature.StatutoryHoliday,
    Feature.TimeEvaluationLogs,
    Feature.AllowDoubleBooking,
    Feature.WeeklyTimesheets,
    Feature.NLP,
    Feature.AutoAssignment,
    Feature.DemandForecastingPoC,
    Feature.RuleBuilderPoC,
    Feature.WeeklyTimesheetsPoC,
    Feature.GardaEmployeesDaily,
    Feature.GardaReports,
    Feature.AldoPayroll,
    Feature.EmploymentTerms,
    // Note: leaving here for now until we have a super admin panel
    Feature.WorkflowBuilder,
  ],
  [Plan.ICC]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.EmploymentTerms,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.News,
    Feature.Chats,
    Feature.AccessLevels,
    Feature.Clocks,
    Feature.PublicHolidays,
    Feature.Locations,
    // Note: leaving here for now until we have a super admin panel
    Feature.TimeBuckets,
    Feature.TimeCardsAutoSettings,
    Feature.TimeAndAttendance,
    Feature.ReportBuilder,
    Feature.CustomLabelDays,
    Feature.Leaves,
    Feature.Dashboard,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.Classifiers,
    Feature.Groups,
    Feature.WorkWeek,
    Feature.TimeType,
    Feature.AutoShiftName,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.GlobalSearch,
    Feature.Integrations,
    Feature.IntegrationsSettings,
    Feature.EarningType,
    Feature.EmploymentTerms,
    // Note: leaving here for now until we have a super admin panel
    Feature.WorkflowBuilder,
    Feature.TimeClassification,
    Feature.TimeEvaluation,
  ],
  [Plan.TBS]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.NotificationsPage,
    Feature.News,
    Feature.Chats,
    Feature.AccessLevels,
    Feature.Clocks,
    Feature.PublicHolidays,
    Feature.Clients,
    Feature.Locations,
    Feature.TimeCardsAutoSettings,
    Feature.TimeAndAttendance,
    Feature.ReportBuilder,
    Feature.FormBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.TaskBuilder,
    Feature.Leaves,
    Feature.BulkShiftImport,
    Feature.Hathor,
    Feature.Dashboard,
    Feature.BulkImport,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.WorkSchedule,
    Feature.Classifiers,
    Feature.Groups,
    Feature.WorkWeek,
    Feature.TimeType,
    Feature.AutoShiftName,
    Feature.PayFrequency,
    Feature.PayrollAccount,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.GlobalSearch,
    Feature.EmploymentTerms,
    Feature.EarningType,
    // Note: leaving here for now until we have a super admin panel
    Feature.WorkflowBuilder,
    Feature.Integrations,
    Feature.TimeClassification,
    Feature.TimeEvaluation,
  ],
  [Plan.WorkAxleManagement]: [
    Feature.Schedule,
    Feature.Shifts,
    Feature.TimeCards,
    Feature.Employees,
    Feature.EmployeesTags,
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.AccessLevels,
    Feature.Payroll,
    Feature.Clocks,
    Feature.Clients,
    Feature.NotificationsPage,
    Feature.TimeAndAttendance,
    Feature.TimeCardsAutoSettings,
    Feature.Locations,
    Feature.ReportBuilder,
    Feature.PrayerBreaks,
    Feature.CustomLabelDays,
    Feature.MassImport,
    Feature.DailyWorkingHours,
    Feature.TaskBuilder,
    Feature.FormBuilder,
    Feature.Leaves,
    Feature.Hathor,
    Feature.Dashboard,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.CustomFields,
    Feature.ShiftTags,
    Feature.EmploymentTerms,
  ],
  [Plan.WorkAxleCommunity]: [
    Feature.News,
    Feature.Employees,
    Feature.EmployeesInvite,
    Feature.Chats,
    Feature.Notifications,
    Feature.Availability,
    Feature.TimeOffs,
    Feature.Availability,
    Feature.TimeOffs,
  ],
  [Plan.WorkAxleJobs]: [
    Feature.EmployeesInvite,
    Feature.Notifications,
    Feature.AccessLevels,
    Feature.Availability,
    Feature.TimeOffs,
  ],
  [Plan.WorkaxleClients]: [Feature.Clients],

  [Plan.AutoAssignment]: [Feature.AutoAssignment],

  [Plan.AutoAssignmentV2]: [Feature.AutoAssignmentV2],

  [Plan.NLP]: [Feature.NLP],

  [Plan.PoC]: [
    Feature.DemandForecastingPoC,
    Feature.RuleBuilderPoC,
    Feature.WeeklyTimesheetsPoC,
  ],

  [Plan.TimeBuckets]: [Feature.TimeBuckets],

  [Plan.Integrations]: [Feature.Integrations],

  [Plan.WeeklyTimesheets]: [Feature.WeeklyTimesheets],

  [Plan.ShiftBidding]: [Feature.ShiftBidding],

  [Plan.WorkflowBuilder]: [
    Feature.WorkflowBuilder,
    Feature.TimeClassification,
    Feature.TimeEvaluation,
    Feature.ShiftValidation,
    Feature.EarningType,
  ],

  [Plan.ShiftEditOverrideForAssignment]: [
    Feature.ShiftEditOverrideForAssignment,
    Feature.ShiftEditingThreshold,
  ],

  [Plan.LeaveBalanceInfo]: [Feature.LeaveBalanceInfo],

  [Plan.UnpublishSchedules]: [Feature.UnpublishSchedules],
}
