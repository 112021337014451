import { DateTime } from 'luxon'

import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

import { SHIFTS_REPEAT } from 'constants/shifts'

import { areasToFilters } from 'helpers/areas'
import { createAsyncAction } from 'helpers/redux'
import { getShiftOffset } from 'helpers/shifts'

import apiCall from 'services/API'
import Utils from 'services/Utils'

import { repeatShifts } from 'store/actions/helpers/shifts'
import { getCompanyId } from 'store/selectors/viewer'

import { mapScheduleFiltersToServerData } from '../helpers/schedules'

export const LOAD_EMPLOYEES = createAsyncAction(
  'company/schedule/LOAD_EMPLOYEES',
)
export const LOAD_EMPLOYEES_SILENT = createAsyncAction(
  'company/schedule/LOAD_EMPLOYEES_SILENT',
)

export const CLEAR_EMPLOYEES = 'company/schedule/CLEAR_EMPLOYEES'

export const CREATE_SHIFT = createAsyncAction('company/schedule/CREATE_SHIFT')
export const PUBLISH = createAsyncAction('company/schedule/PUBLISH')

export const DELETE_SCHEDULES = createAsyncAction(
  'company/schedule/DELETE_SCHEDULES',
)

const loadEmployeesInclude = [
  'activeTimeEntry.branch',
  'activeTimeEntry.department',
  'branch.prayerSettings.branch',
  'dayLabels.customLabel',
  'jobs',
  'jobsEmployees.department',
  'profile.profileAvatar',
  'schedules.acceptedShiftTrades.acceptedEmployee.profile',
  'schedules.fromShiftTrades.fromEmployee.profile',
  'schedules.shift.branch',
  'schedules.job',
  'schedules.department',
  'schedules.shift.shiftTags',
  'schedules.shift.pauses',
  'schedules.shiftCovers.fromEmployee.profile',
  'schedules.timeEntry',
  'timeoffs.customLeaveDay',
  'branches',
  'schedules.shift.timeBucket',
  'schedules.shift.timeBucket.timeBucketParent',
  'schedules.scheduleAcceptance',
  'schedules.shiftsJob.open',
  'schedules.shiftsJob.shiftJobGroups',
  'schedules.shiftsJob.shiftJobGroups.group',
]

export const loadEmployees = (
  {
    from,
    to,
    areas = {},
    selectedEmployees = null,
    shiftTags,
    shiftTagsFilterMode,
    startingAfter,
    pageSize = 20,
    timeBucketParents,
    timeBucketChildren,
    withShifts,
  } = {},
  { withLoading = true } = {},
) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const timeBucketFilter = Utils.TimeBucket.selectedTimeBucketsToJsonApiFilters(
    {
      timeBucketChildren,
      timeBucketParents,
    },
  )

  const filter = {
    start_at: {
      between: [from.toISO(), to.toISO()],
    },
    ...areasToFilters(areas),
    ...timeBucketFilter,
    with_shifts: withShifts,
  }

  if (!isEmpty(shiftTags)) {
    filter.shift_tag_ids = {
      [shiftTagsFilterMode === 'or' ? 'any' : 'all']: shiftTags.map(
        shiftTag => shiftTag.value,
      ),
    }
  }

  if (selectedEmployees?.length) {
    filter.employee_id = { in: selectedEmployees.map(option => option.value) }
  }

  return dispatch(
    apiCall({
      endpoint: `/companies/${companyId}/schedules/employees`,
      types: withLoading ? LOAD_EMPLOYEES : LOAD_EMPLOYEES_SILENT,
      query: {
        include: loadEmployeesInclude.join(),
        filter,
        page: {
          size: pageSize,
          starting_after: startingAfter,
        },
      },
      paged: true,
    }),
  )
}

export const clearEmployees = () => ({ type: CLEAR_EMPLOYEES })

const createShiftIncludes = [
  'branch',
  'shiftsJobs.schedules.employee',
  'shiftsJobs.schedules.shift',
  'shiftsJobs.schedules.timeEntry',
  'shiftsJobs.shiftJobGroups',
  'shiftsJobs.shiftJobGroups.group',
  'pauses',
  'shiftTags',
  'shiftsJobs.schedules.job',
  'shiftsJobs.schedules.department',
  'timeBucket',
  'timeBucket.timeBucketParent',
]

export const copySchedules = ({
  dataSchedules,
  withoutDispatch,
}) => dispatch => {
  return dispatch(
    apiCall({
      endpoint: '/shifts',
      types: !withoutDispatch && CREATE_SHIFT,
      method: 'POST',
      query: {
        data: dataSchedules,
        include: createShiftIncludes.join(),
      },
    }),
  )
}

export const createShift = ({
  name,
  note,
  shiftTags,
  repeat,
  untilDate,
  pauses,
  days = [],
  locationId = null,
  departmentId = null,
  employeeId = null,
  jobId = null,
  color = null,
  startAt,
  finishAt,
  timezone,
  blockTrading,
  blockCoverage,
  timeBucketId,
  shiftJobGroups,
} = {}) => dispatch => {
  const attributes = {
    name,
    startAt,
    finishAt,
    shift_tag_ids: shiftTags,
    branch_id: locationId,
    block_trading: blockTrading,
    block_donating: blockCoverage,
    ...(timeBucketId && { time_bucket_id: timeBucketId }),
  }

  if (color) {
    attributes.color = color
  }

  if (note) {
    attributes.note = note
  }

  const data = {
    type: 'shifts',
    attributes,
    relationships: {
      employee: {
        data: {
          type: 'employees',
          id: employeeId,
        },
      },
      department: {
        data: {
          type: 'departments',
          id: departmentId,
        },
      },
      job: {
        data: {
          type: 'jobs',
          id: jobId,
        },
      },
    },
  }

  const shiftStartDate = DateTime.fromISO(startAt, { zone: timezone })

  const shifts = [
    data,
    ...days
      // Remove shift start day dates as they're already present in `startAt` and `finishAt`
      .filter(day => {
        return !day.hasSame(shiftStartDate, 'day')
      })
      .map(day => {
        const [nextStartAt, nextFinishAt] = getShiftOffset(
          { startAt, finishAt, branch: { settings: { timezone } } },
          day,
        )
        return {
          ...data,
          attributes: {
            ...data.attributes,
            startAt: nextStartAt,
            finishAt: nextFinishAt,
          },
        }
      }),
  ]

  const isRepeat = repeat === SHIFTS_REPEAT.UNTIL
  const repeatedShifts = repeatShifts(shifts, isRepeat, untilDate)

  return dispatch(
    apiCall({
      endpoint: '/shifts',
      types: CREATE_SHIFT,
      method: 'POST',
      query: {
        data: repeatedShifts,
        include: createShiftIncludes.join(),
      },
      payload: {
        pauses,
        shiftJobGroups,
      },
    }),
  )
}

export const publishWithFilters = ({
  from,
  to,
  areas,
  shiftTags,
  shiftTagsFilterMode,
  selectedSchedules,
  selectedEmployees,
}) => (dispatch, getState) => {
  const companyId = getCompanyId(getState())

  const payload = mapScheduleFiltersToServerData({
    from,
    to,
    areas,
    shiftTags,
    shiftTagsFilterMode,
    selectedSchedules,
    selectedEmployees,
  })

  return dispatch(
    apiCall({
      method: 'POST',
      endpoint: `/companies/${companyId}/schedules/publish`,
      types: PUBLISH,
      query: {
        data: {
          attributes: payload,
        },
      },
    }),
  )
}

export const deleteSchedules = schedules => (dispatch, getState) => {
  const data = []
  const deletedIds = []
  const employeeSchedules = {}

  forEach(schedules, ({ id, employee }) => {
    data.push({ type: 'schedules', id })
    deletedIds.push(id)

    if (employee) {
      if (!employeeSchedules[employee.id]) {
        employeeSchedules[employee.id] = []
      }

      employeeSchedules[employee.id].push(id)
    }
  })

  return dispatch(
    apiCall({
      endpoint: `/companies/${getCompanyId(
        getState(),
      )}/relationships/schedules`,
      types: DELETE_SCHEDULES,
      method: 'DELETE',
      query: {
        data,
      },
      payload: { deletedIds, employeeSchedules },
    }),
  )
}
